import React from "react";
import Layout from "../components/layout";

const CFP = () => (
  <Layout>
    <div>Hello</div>
  </Layout>
);

export default CFP;
